import {render} from 'lit';
import Viewport from '../../ts/services/viewport';
import {AjaxP, DOM, PubSub, Logger} from '@cad/static-next-lib';
import Tracking2 from './tracking2';
import {wblayer} from '../../modules/mod-welcomeback/template/wblayer';

interface JsonData {
  mandant: string;
  recommended: TeaserData[];
  clickSuffix: string;
}

class CookieData {
  hide: boolean;
  timestamp: number;
}

interface TeaserData {
  html: string;
}

/**
 * The Welcomeback layer
 */
class Welcomeback {
  name_ = 'welcomeback';
  dataURL_ = '';
  moduleNode: HTMLElement | null;
  welcomeBackTimer_: ReturnType<typeof setTimeout>;
  shutdown = false;
  welcomeBackTimeOut = (window.ui.wb && window.ui.wb.timeout >= 0) ? window.ui.wb.timeout : undefined;

  /**
   * Logs an error message and reports the error to Sentry
   * @param {unknown} e
   */
  logError(e: unknown) {
    Logger.logUnknownError(e, this.name_);
    PubSub.publish('sentry:log', {error: e, module: this.name_});
  }

  /**
   * Starts the module
   */
  start() {
    try {
      // Cancel module initialization if there is no ui.wb.timeout object
      if (this.welcomeBackTimeOut === undefined || Viewport.isMobile()) {
        Logger.log(this.name_, 'No ui.wb.timeout object in body. Module init cancelled.');
        return;
      }

      PubSub.subscribe('welcomeback:restartWBTimer', () => {
        Logger.log(this.name_, 'welcomeback:restartWBTimer');
        this.shutdown = false;
        this.restartWBTimer();
      });

      // optional parameter: if its anything but false, the scrollEvent wont trigger the WBS again
      PubSub.subscribe('welcomeback:stopWBTimer', opt => {
        // used so far @ comments onFocus
        this.shutdown = opt !== undefined;
        if (this.welcomeBackTimer_) clearTimeout(this.welcomeBackTimer_);
      });

      PubSub.subscribe('windowScrolling', () => {
        this.restartWBTimer();
      });

      PubSub.subscribe('visibilityStateChanged', event => {
        this.handleVisibilityStateChange(event as Event);
      });
    } catch (e) {
      this.logError(e);
    }
  }

  /**
   * request Ad on container
   * @param {HTMLElement} domNode
   */
  showAd(domNode: HTMLElement) {
    // Ad only for desktop CADNPCA-4923
    if (!Viewport.isDesktop()) {
      return;
    }

    const adCon = domNode.getElementsByClassName('co-container')[0];

    if (adCon) {
      PubSub.publish('dynamicAdsNeeded', [adCon]);
    }
  }

  /**
   * store jsonData in global var, after page is visible again
   */
  handleJsonData() {
    Logger.log(this.name_, 'handleJsonData()');
    AjaxP.getJSON(this.dataURL_).then(response => {
      this.openWelcomeBack(response as JsonData);
    }).catch(e => this.logError(e));
  }

  /**
   * render welcomeBackScreen when cookie is available & JSON got requested
   * @param {object} [jsonData] contains wb markup
   */
  openWelcomeBack(jsonData: JsonData) {
    Logger.log(this.name_, 'openWelcomeBack()');

    try {
      const domFragment = DOM.fromHtml('');
      render(wblayer(jsonData.mandant, jsonData.recommended), domFragment);
      const tmpElement = document.createElement('div');
      tmpElement.style.display = 'none';
      tmpElement.appendChild(domFragment);

      PubSub.publish('overlay:showWelcomeback', {
        content: tmpElement.innerHTML,
        module: 'welcomelayer',
        opts: {wbTrackingVar: jsonData.clickSuffix},
        callback: (dialogBoxContent: HTMLElement) => {
          this.moduleNode = dialogBoxContent.querySelector('[data-mod-name="welcomeback"]');

          if (this.moduleNode) {
            this.handleWBEvents(this.moduleNode);
            // hp ad on wb dialog (CADNPCA-4923)
            this.showAd(this.moduleNode);
          }
        }
      });
    } catch (e) {
      this.logError(e);
    }
  }

  /**
   * set several cookies, depending on there context
   * @param {string} [cookieName] gonna be a cookie obj later on
   */
  setWBCookie(cookieName: string) {
    const cookieData = new CookieData();

    try {
      switch (cookieName) {
        case 'hideForever':
          cookieData.hide = true;
          break;
        case 'pageInactive':
          cookieData.timestamp = Date.now();
          break;
        default:
          // do nothing
          break;
      }

      // check if cookiedata is available, to prevent different weird behaviour
      if (Object.keys(cookieData).length > 0) {
        localStorage.setItem(cookieName, JSON.stringify(cookieData));
      }
    } catch (e) {
      this.logError(e);
    }
  }

  /**
   * function to read different kind of cookies, depending on their name
   * @param {string} [cookieName] contains the cookie name
   * @return {boolean}
   */
  handleCookieData(cookieName: string): boolean {
    let retVal = false;

    Logger.log(this.name_, 'handleCookieData ' + cookieName);

    try {
      retVal = this.parseLocalStorage(cookieName);
    } catch (e) {
      this.logError(e);
    }
    return retVal;
  }

  /** parse localStorage data */
  parseLocalStorage(cookieName: string): boolean {
    let retVal = false;

    try {
      const localStorageData = localStorage.getItem(cookieName);

      if (localStorageData) {
        const parsedData = JSON.parse(localStorageData) as CookieData;

        if (parsedData) {
          Logger.log(this.name_, 'SESSIONSTORAGE AVAILABLE: ' + cookieName);
          Logger.log(this.name_, localStorageData);

          if (cookieName === 'hideForever') {
            retVal = parsedData.hide;
          } else if (cookieName === 'pageInactive') {
            if (this.compareTime(parsedData.timestamp, this.welcomeBackTimeOut || 0)) {
              retVal = true;
            }
          }
        }
      }
    } catch (e) {
      this.logError(e);
    }

    return retVal;
  }

  /**
   * Request loading of modules and handle events within the layer
   * @param {HTMLElement} [wbElement] The main welcomeback element
   */
  handleWBEvents(wbElement: HTMLElement) {
    try {
      // initialize teaser wrapper again
      PubSub.publish('mod:dynInit', wbElement);

      // publish to analytics for brainzone click-tracking
      PubSub.publish('welcomeback:brainzone', wbElement);

      // noThanks (please dont show me this screen anymore) element
      const noThanks = wbElement.getElementsByClassName('js-shownomore')[0];

      if (noThanks) {
        noThanks.addEventListener('click', e => {
          e.preventDefault();
          e.stopPropagation();

          this.setWBCookie('hideForever');

          PubSub.publish('overlay:close', {
            moduleName: 'welcomelayer',
            optTrackingName: '_close_remembered'
          });
          const target = e.currentTarget as HTMLElement;
          const componentLabel = target.innerText ?? target.getAttribute('aria-label') ?? '';
          const elementTagName = target.tagName.toLowerCase();
          Tracking2.callTealiumLink({
            businessEventType: 'userAction',
            componentPath: 'welcomelayer.dialogcontent.welcomeback',
            componentResultState: 'close_remembered',
            componentLabel,
            elementTagName,
            eventType: 'mousedown'
          });
        });
      }

      // Back to article button
      const backButton = wbElement.getElementsByClassName('js-backbutton')[0];

      if (backButton) {
        backButton.addEventListener('click', e => {
          e.preventDefault();
          e.stopPropagation();

          PubSub.publish('overlay:close', {
            moduleName: 'welcomelayer'
          });
          const target = e.currentTarget as HTMLElement;
          const componentLabel = target.innerText ?? target.getAttribute('aria-label') ?? '';
          const elementTagName = target.tagName.toLowerCase();
          Tracking2.callTealiumLink({
            businessEventType: 'userAction',
            componentPath: 'welcomelayer.dialogcontent.welcomeback',
            componentResultState: 'close_back',
            componentLabel,
            elementTagName,
            eventType: 'mousedown'
          });
        });
      }
    } catch (e) {
      this.logError(e);
    }
  }

  /**
   * Handle visibility state change
   * @param {Event} e
   */
  handleVisibilityStateChange(e: Event) {
    const pageHidden = (e.target as HTMLElement).hidden;

    // if page is in the foreground & there is no other WB-Overlay opened
    if (!pageHidden) {
      // prevent opening several overlays
      if (document.querySelector('[data-mod-name="welcomeback"]') !== null) {
        // don't create overlay if the user clicked on 'don't show again' or wasn't idle long enough
        const hideForever = this.handleCookieData('hideForever');
        const pageInactive = this.handleCookieData('pageInactive');
        if (!hideForever && pageInactive) {
          this.handleJsonData();
        }
      }
    } else {
      // getJsonUrl after page got hidden
      this.dataURL_ = window.ui.wb.dataUrl;
      this.setWBCookie('pageInactive');
    }
  }

  /**
   * compare [timestamp] with [timeDifference] and return true if the difference between those two has surpassed
   * @param {number} [timestamp] the timestamp to compare the difference with
   * @param {number} [timeDifference] to compare the given timestamp with
   * @return {boolean}
   */
  compareTime(timestamp: number, timeDifference: number): boolean {
    let retVal = false;

    try {
      const compare = (Date.now() - timestamp);
      if (compare >= timeDifference) {
        retVal = true;
      }
    } catch (e) {
      this.logError(e);
    }
    return retVal;
  }

  /**
   * Restart welcomeBack timeout (triggers publish, after timeout has ended)
   */
  restartWBTimer() {
    try {
      if (this.welcomeBackTimer_ !== undefined) {
        clearTimeout(this.welcomeBackTimer_);
      }

      if (!this.shutdown) {
        this.welcomeBackTimer_ = setTimeout(() => {
          this.dataURL_ = window.ui.wb.dataUrl;
          if (document.querySelector('[data-mod-name="welcomeback"]') === null
            && !this.handleCookieData('hideForever')) {
            this.handleJsonData();
          }
        }, this.welcomeBackTimeOut);
      }
    } catch (e) {
      this.logError(e);
    }
  }
}

export default new Welcomeback();
